<template>
	<div class="mx-4">
		<v-tabs v-model="tabmodel" slider-color="primary">
			<v-tab v-if="$store.getters.hasFunctionality('READ_GISWEB_OPERATIONS')" :key="0" :href="'#operations'">{{
				$t('gisweb.operations.title')
			}}</v-tab>
			<v-tab v-if="$store.getters.hasFunctionality('READ_GISWEB_ENTRY')" :key="1" :href="'#entry'">{{ $t('gisweb.entry.title') }}</v-tab>
			<v-tab v-if="$store.getters.hasFunctionality('READ_GISWEB_EXIT')" :key="2" :href="'#exit'">{{ $t('gisweb.exit.title') }}</v-tab>
			<v-tab v-if="$store.getters.hasFunctionality('READ_GISWEB_REPAIRING')" :key="3" :href="'#repair'">{{ $t('gisweb.repair.title') }}</v-tab>
		</v-tabs>
		<v-divider class="mb-4"></v-divider>
		<v-tabs-items v-model="tabmodel">
			<v-tab-item :key="0" lazy :value="'operations'">OPERATIONS</v-tab-item>
			<v-tab-item :key="1" lazy :value="'entry'"><GiswebEntry :filter="filter" /></v-tab-item>
			<v-tab-item :key="2" lazy :value="'exit'"> EXIT</v-tab-item>
			<v-tab-item :key="3" lazy :value="'repair'"> REPAIR</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import GiswebEntry from './sections/GiswebEntry.vue';

export default {
	components: {
		GiswebEntry
	},
	mixins: [],
	data() {
		return {
			tabmodel: 1,
			filter: {
				dateini: null,
				dateend: null,
				shiptype: null,
				berth: null,
				text: null
			}
		};
	},
	computed: {}
};
</script>

<style scoped></style>
