<template>
	<div>
		<GiswebToolbar :filter="filter" />
		<v-row class="d-flex justify-space-around">
			<v-col class="listContainer" cols="7">
				<div style="overflow-y: auto">
					<span class="d-flex">
						<v-btn color="primary" class="elevation-0 mb-2 mr-auto">
							<v-icon class="mr-1" small>fa fa-file-export</v-icon> | <v-icon class="ml-1" small>fas fa-chevron-down</v-icon>
						</v-btn>
						<v-btn class="elevation-0 mr-4" width="15" style="width: 15px; background-color: var(--low)" @click="orderStops">
							<v-icon small>fa-kit fa-line-order-1</v-icon>
						</v-btn>
						<v-btn class="elevation-0" color="primary" @click="modalOpened = true">
							<v-icon small>fa-kit fa-line-information-consultation</v-icon>
						</v-btn>
					</span>
					<v-expansion-panels multiple flat tile outlined v-model="panel">
						<v-expansion-panel v-for="(item, i) in stops" :key="i" active-class="expansionactive" class="expansion">
							<v-expansion-panel-header hide-actions style="height: 140px">
								<CardHeader :stop="item" />
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<CardContent :stop="item" />
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
				<gisweb-legend v-model="modalOpened" />
			</v-col>
			<v-col cols="4" class="mapContainer">MAP</v-col>
		</v-row>
	</div>
</template>

<script>
import GiswebToolbar from '../toolbar/GiswebToolbar.vue';
import CardHeader from './cards/CardHeader.vue';
import CardContent from './cards/CardContent.vue';
import GiswebLegend from '../legend/GiswebLegend.vue';
export default {
	components: {
		GiswebToolbar,
		CardHeader,
		CardContent,
		GiswebLegend
	},
	mixins: [],
	data() {
		return {
			tabmodel: 0,
			model: {
				dateini: null,
				dateend: null
			},
			panel: [],
			stops: [],
			modalOpened: false,
			asc: true
		};
	},
	props: {
		filter: {
			type: Object,
			required: true
		}
	},
	computed: {},
	created() {
		this.getStops();
	},
	methods: {
		getStops() {
			let rules = [];

			this.filter.dateini ? rules.push({ field: 'eta', op: 'ge', data: this.filter.dateini }) : null;
			this.filter.dateend ? rules.push({ field: 'eta', op: 'le', data: this.filter.dateend }) : null;
			this.filter.shiptype ? rules.push({ field: 'vesseltype', op: 'eq', data: this.filter.shiptype }) : null;
			this.filter.berth ? rules.push({ field: 'locationid', op: 'eq', data: this.filter.berth }) : null;
			this.filter.text ? rules.push({ field: 'vesselname', op: 'cn', data: this.filter.text }) : null;

			const opts = {
				model: 'stopentry',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: rules
				},
				order: [
					{
						column: 'eta',
						direction: 'asc'
					}
				]
			};
			this.$puiRequests.postRequest('/stop/getStopsGisweb', opts, (response) => {
				this.stops = response.data.data;
			});
		},
		orderStops() {
			this.asc = !this.asc; // Alterna el valor de asc
			if (this.asc) {
				this.stops.sort((a, b) => {
					return new Date(a.eta) - new Date(b.eta); // Orden ascendente
				});
			} else {
				this.stops.sort((a, b) => {
					return new Date(b.eta) - new Date(a.eta); // Orden descendente
				});
			}
		}
	},
	watch: {
		filter: {
			handler() {
				this.getStops();
			},
			deep: true
		}
	}
};
</script>

<style>
@import '../../../styles/app-variables.pcss';
.listContainer {
	background-color: var(--very-low);
	border-radius: 1%;
	overflow: auto;
	height: calc(100vh - 230px);
}

.mapContainer {
	background-color: var(--very-low);
	border-radius: 1%;
	height: calc(100vh - 230px);
}

.expansion {
	border-radius: 8px !important;
	padding-bottom: 0px !important;
	border: 1px solid var(--low) !important;
	margin-bottom: 10px;
}

.expansionactive {
	border-radius: 8px !important;
	background-color: lightblue !important;
	border: 1px solid var(--primary) !important;
}
</style>
